import React, {useState} from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import Iframe from 'react-iframe'

import {Button,Navbar,NavbarBrand,NavItem,NavLink,Nav} from 'reactstrap';

function App() {

  const [activePage,setActivePage] = useState("home");

  return (
    <div className="App">
    {activePage == "home" ?
      <header className="App-header">
        <img src="./imb-logo.png" className="App-logo" alt="logo" />
        <p>
          Welcome to IMB Bank
        </p>
        <a className="App-link" href="#" onClick={() => setActivePage("https://internetbanking.imb.com.au")}>Internet Banking</a>
      </header>
      : '' }
    {activePage != "home" ?
    <div>
      <Navbar color="light" light expand="md" className="fixed-top">
          <NavbarBrand onClick={() => setActivePage("home")}><img src="./imb-logo.png" alt="logo" style={{height: '30px'}}/></NavbarBrand>
          <Nav>
          <NavItem>
            <NavLink onClick={() => setActivePage("home")}>Home</NavLink>
          </NavItem>
          </Nav>
      </Navbar>
      <Iframe
          url={activePage}
          width="100%"
          height="100%"
          className="iframe"
          display="initial"
          position="relative"
          id="iframe-active"
          sandbox="allow-forms allow-pointer-lock allow-popups allow-same-origin allow-scripts allow-top-navigation"
        />
    </div>
    : ''}
    </div>
  );
}

export default App;
